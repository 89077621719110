import {Column, Text} from 'native-base';
import {FeaturedChallenges} from '@ui/features/clubhouse/FeaturedChallenges';
import IcGroup from '@ui/svg/IcGroup';
import {LinkedSettingsRow} from '@ui/components/settings/SettingsRow';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';
import {useDashboardAssets} from '@ui/features/clubhouse/hooks/dashboard-hooks';
import {useTranslation} from 'react-i18next';

export const CompetitionSearchFeaturedChallenges = () => {
    const [config] = useDashboardAssets();
    const featuredChallenges = config?.searchChallenges?.challengesWithLabel ?? [];
    const title = config?.searchChallenges?.title?.en;
    const description = config?.searchChallenges?.description?.en;

    const {t} = useTranslation();

    return (
        <Column space='4' w='full' p='4'>
            {title && <Text variant='h5'>{title}</Text>}

            {description && <Text variant='body' color='blacks.700'>{description}</Text>}

            <FeaturedChallenges items={featuredChallenges} />

            <LinkedSettingsRow _row={{p: 6}} label={t('train.searchCountryClub')} left={<IcGroup />} _link={{path: {pathname: getScreen('creators')}}} />
        </Column>
    );
};
