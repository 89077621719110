import algoliasearch, {SearchClient} from 'algoliasearch/lite';

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!;
const secret = process.env.NEXT_PUBLIC_ALGOLIA_SECRET!;

const indexes = {
    clubs: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_CLUBS!,
    users: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_USERS!,
    competitions: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_COMPETITIONS!,
    creators: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_CREATORS!,
};

export {indexes};

let searchClient: SearchClient;

const getSearchClient = (): SearchClient => {
    if (!searchClient) {
        searchClient = algoliasearch(appId, secret);
    }
    return searchClient;
};

export default getSearchClient;

const getIndexes = () => {
    return indexes;
};

export {getIndexes};
