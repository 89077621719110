

import {Box, Center, Text} from 'native-base';
import React, {useMemo} from 'react';
import AlgoliaProvider from '@ui/provider/algolia';
import CompetitionCard from '../components/CompetitionCard';
import {CompetitionSearchFeaturedChallenges} from './CompetitionSearchFeaturedChallenges';
import {CompetitionUiPreviewData} from '@shared/models/competition';
import IcGroup from '@ui/svg/IcGroup';
import InfiniteHits from '@ui/components/InfiniteHits';
import NavigationButton from '@ui/components/buttons/NavigationButton';
import SearchBox from '@ui/components/SearchBox';
import {getIndexes} from '@ui/provider/algolia/searchClient';
import {getScreen} from '@ui/provider/navigation/screens';
import useCommunityFilter from './hooks/useCommunityFilter';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';
import {useWindowDimensions} from 'react-native';

const ONGOING_STATUS = 'status:REGISTRATION OR status:ONGOING OR status:UPCOMING OR status:FINISHED OR status:REGISTRATION_ENDED';
const SEARCH_STATUS = `${ONGOING_STATUS} OR status:CANCELED`;

interface Props {
    Header?: JSX.Element
    Footer?: JSX.Element
    EmptyView?: JSX.Element;
    extraFilter?: string;
    renderItem?: (item: CompetitionUiPreviewData) => JSX.Element;
    showHeaderOnSearch?: boolean;
    showSearch?: boolean;
    searchPlaceholder?: string;
}

export const CompetitionSearchList = (props: Props) => {
    const {Header = <Box h='4' />, Footer, renderItem, extraFilter, showHeaderOnSearch = true, showSearch = true, EmptyView, searchPlaceholder} = props;
    const [search, setSearch] = React.useState('');
    const {user} = useUser();
    const competitions = Object.values(user?.competitions ?? {});

    const hasSearch = search.length > 0;

    const filter = useMemo(() => hasSearch ? SEARCH_STATUS : [ONGOING_STATUS, extraFilter].filter(Boolean).join(' AND '), [extraFilter, hasSearch]);

    return (
        <AlgoliaProvider
            indexName={getIndexes()['competitions']}
            filters={filter}
        >
            {showSearch && <SearchBox setSearch={setSearch} placeholder={searchPlaceholder}/>}

            <InfiniteHits
                ItemSeparatorComponent={() => (
                    <Box h='4'/>
                )}
                ListHeaderComponent={showHeaderOnSearch || !hasSearch ? Header : <Box h='4' />}
                ListFooterComponent={showHeaderOnSearch || !hasSearch ? Footer : <Box h='4' />}
                ListEmptyComponent={EmptyView}
                renderItem={({item}: {item: CompetitionUiPreviewData}) =>
                    renderItem ? renderItem(item) :
                        <Box px='4'>
                            <CompetitionCard
                                competition={item}
                                userJoined={competitions.some(c => c.id === item.id)}
                            />
                        </Box>
                }
                isBottomsheet={false}
            />
        </AlgoliaProvider>
    );
};

const CompetitionEmptyScreen = () => {
    const {t} = useTranslation();

    const {height} = useWindowDimensions();

    return (
        <Center flex={1} justifyContent="center" alignItems="center" p='4' h='full' minH={height / 2}>
            <Text variant="medium" textAlign="center" mb='8'>{t('competition.list.exploreTournaments.empty')}</Text>

            <NavigationButton path={{pathname: getScreen('creators')}} w='full' variant="lime" startIcon={<IcGroup />}>
                {t('train.joinCountryClub')}
            </NavigationButton>
        </Center>
    );
};

const CHALLENGE_TYPE = 'challengeType:COMPETITION';

const CompetitionSearchListWithFeaturedChallenges = (props: Props) => {
    const {t} = useTranslation();

    const communityFilter = useCommunityFilter();
    const hasCommunityFilter = communityFilter.length > 0;


    if(!hasCommunityFilter) {
        return (
            <CompetitionEmptyScreen />
        );
    }

    const filter = hasCommunityFilter ? communityFilter.concat(` AND ${CHALLENGE_TYPE}`) : CHALLENGE_TYPE;

    return (
        <CompetitionSearchList
            Header={<CompetitionSearchFeaturedChallenges />}
            extraFilter={filter}
            searchPlaceholder={t('search.events')}
            {...props}
        />
    );
};


export default CompetitionSearchListWithFeaturedChallenges;
