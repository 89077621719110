import {ITextProps, Row, Text} from 'native-base';
import NavigationLink, {NavigationLinkProps} from '../buttons/NavigationLink';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import IcChevronRight from '@ui/svg/IcChevronRight';
import IconCircle from '../label/IconCircle';
import React from 'react';
import {TouchableOpacity} from 'react-native';

const TouchableOpacityStyle = {
    width: '100%',
};

interface SettingsRowProps {
    label: string;
    onPress?: () => void;
    left?: JSX.Element;
    right?: JSX.Element;
    _text?: ITextProps;
    showChevron?: boolean;
    _row?: IHStackProps;
    _chevron?: any;
}

const SettingsRow = ({label, onPress, right, _text, _row, _chevron, showChevron = true}: SettingsRowProps) => {
    return (
        <TouchableOpacity style={TouchableOpacityStyle} onPress={onPress} disabled={onPress === undefined} >
            <Row alignItems="center" bg='white' {..._row}>
                <Text variant='mediumSemiBold' fontWeight={400} flex={1} {..._text}>{label}</Text>

                {right ? right : onPress && showChevron ? <IconCircle><IcChevronRight style={_chevron}/></IconCircle> : null}
            </Row>
        </TouchableOpacity>
    );
};

interface LinkedProps extends SettingsRowProps {
    _link: Omit<NavigationLinkProps, 'children'>;
}

const LinkedSettingsRow = ({label, _text, _row, _chevron, _link, left, right}: LinkedProps) => {
    return (
        <NavigationLink {..._link}>
            <Row alignItems="center" bg='white' space='4' rounded='lg' overflow="hidden" {..._row}>
                {left}

                <Text variant='bodyLg' fontWeight={400} flex={1} {..._text}>{label}</Text>

                {right ? right :
                    <IconCircle>
                        <IcChevronRight style={_chevron}/>
                    </IconCircle>}
            </Row>
        </NavigationLink>
    );
};

export {LinkedSettingsRow};

export default SettingsRow;
