

import {Box, IBoxProps} from 'native-base';
import React, {useCallback, useRef, useState} from 'react';
import {UseSearchBoxProps, useSearchBox} from 'react-instantsearch-core';
import {SearchField} from '@ui/components/forms/Fields';
import {TextInput} from 'react-native';
import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';

interface Props extends UseSearchBoxProps, IBoxProps {
    setSearch?: (search: string) => void;
    placeholder?: string;
}

const SearchBox = (props: Props) => {
    const {setSearch, placeholder} = props;
    const {query, refine} = useSearchBox(props);
    const [inputValue, setInputValue] = useState(query);
    const inputRef = useRef<TextInput>(null);
    const {t} = useTranslation();

    const search = useCallback(debounce((newQuery: string) => {
        refine(newQuery);
        setSearch?.(newQuery);
    }, 300), [refine, setSearch]);

    const setQuery = useCallback((newQuery: string) => {
        setInputValue(newQuery);
        search(newQuery);
    }, [search]);

    // Track when the InstantSearch query changes to synchronize it with
    // the React state.
    // We bypass the state update if the input is focused to avoid concurrent
    // updates when typing.
    if (query !== inputValue && !inputRef.current?.isFocused()) {
        setInputValue(query);
    }

    return (
        <Box px='4' {...props}>
            <SearchField
                inputRef={inputRef}
                value={inputValue}
                onChangeText={setQuery}
                placeholder={placeholder ?? t('actions.search')}
            />
        </Box>
    );
};

export default SearchBox;
