import {Center, ICenterProps} from 'native-base';
import React from 'react';

const IconCircle = (props: ICenterProps) => {
    return (
        <Center rounded='full' w={9} h={9} bg='blacks.100' {...props} />
    );
};

export default IconCircle;
