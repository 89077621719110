import {FlatListProps, Platform} from 'react-native';
import React, {useEffect} from 'react';
import {UseInfiniteHitsProps, useInfiniteHits} from 'react-instantsearch-core';
import BottomSheetFlatList from '@ui/components/gorhom/BottomSheetFlatList';
import {FlatList} from 'native-base';
import {LoadMoreButton} from './List';

type Props = Omit<FlatListProps<any>, 'data'> & UseInfiniteHitsProps & {
    isBottomsheet?: boolean;
};

const InfiniteHits = (props: Props) => {
    const {isBottomsheet = true} = props;
    const {hits, isLastPage, showMore} = useInfiniteHits({...props});
    // This code is needed because react-instantsearch-core doesn't provide a loading more state
    const [loadingMoreHits, setLoadingMoreHits] = React.useState(0);
    const [loadingMore, setLoadingMore] = React.useState(false);

    useEffect(() => {
        if (loadingMoreHits !== hits.length) {
            setLoadingMore(false);
        }
    }, [hits.length, loadingMoreHits]);

    const handleShowMore = async() => {
        setLoadingMoreHits(hits.length);
        setLoadingMore(true);
        showMore();
    };

    if(!isBottomsheet){
        return (
            <FlatList
                renderItem={props.renderItem}
                data={hits}
                keyExtractor={(item) => item.objectID}
                showsVerticalScrollIndicator={false}
                onEndReached={() => {
                    if (!isLastPage && Platform.OS !== 'web') {
                        showMore();
                    }
                }}
                flex={1}
                ListFooterComponent={props.ListFooterComponent ?? <LoadMoreButton loadMore={handleShowMore} loadingMore={loadingMore} hasMore={!isLastPage} />}
                ListHeaderComponent={props.ListHeaderComponent}
                ItemSeparatorComponent={props.ItemSeparatorComponent}
                ListEmptyComponent={props.ListEmptyComponent}
                _contentContainerStyle={style}
            />
        );
    }

    return (
        <BottomSheetFlatList
            renderItem={props.renderItem}
            data={hits}
            keyExtractor={(item) => item.objectID}
            showsVerticalScrollIndicator={false}
            onEndReached={() => {
                if (!isLastPage && Platform.OS !== 'web') {
                    showMore();
                }
            }}
            ListFooterComponent={props.ListFooterComponent ?? <LoadMoreButton loadMore={handleShowMore} loadingMore={loadingMore} hasMore={!isLastPage} />}
            ListHeaderComponent={props.ListHeaderComponent}
            ItemSeparatorComponent={props.ItemSeparatorComponent}
            ListEmptyComponent={props.ListEmptyComponent}
            contentContainerStyle={style}
        />
    );
};

const style = {flexGrow: 1};

export default InfiniteHits;
