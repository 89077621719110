import {FlatList} from 'native-base';
import React from 'react';

const BottomSheetFlatList: typeof FlatList = (props) => {
    return (
        <FlatList
            {...props}
        />
    );
};

export default BottomSheetFlatList;
