import {Configure, ConfigureProps, InstantSearch} from 'react-instantsearch-core';
import React from 'react';
import getSearchClient from '@ui/provider/algolia/searchClient';

export const AlgoliaProvider = ({
    children,
    indexName,
    filters,
    configure,
}: {
    children: React.ReactNode,
    indexName: string
    filters?: string
    configure?: ConfigureProps
}) => {
    return (
        <InstantSearch searchClient={getSearchClient()} indexName={indexName}>
            <Configure
                filters={filters}
                {...configure}
            />

            {children}
        </InstantSearch>
    );
};

export default AlgoliaProvider;
