import {useMemo} from 'react';
import {useUser} from '@ui/provider/user/UserProvider';

const useCommunityFilter = () => {
    const {user} = useUser();
    const communityFilter = useMemo(() =>
        Object.values(user?.community?.entities ?? {}).map(entity => `community.entityIds:${entity.id}`).join(' OR '),
    [user]);

    return communityFilter;
};

export default useCommunityFilter;
