import {CompetitionUiDataWithLabel} from '@shared/models/configs';
import {FeaturedItem} from '@ui/components/FeaturedItem';
import {IBoxProps} from 'native-base';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';

interface Props extends IBoxProps {
    item: CompetitionUiDataWithLabel;
}

export const ChallengeFeaturedItem = (props: Props) => {
    const {item, ...rest} = props;

    const {competition: challenge} = item;
    const label = item.label?.en;

    return (
        <FeaturedItem
            path={{pathname: getScreen('competition'), query: {id: challenge.id}}}
            image={challenge.visuals.banner}
            alt={challenge.name.en}
            label={label}
            width={180}
            {...rest}
        />
    );
};
