import * as React from 'react';
import Svg, {G, Mask, Path, SvgProps} from 'react-native-svg';
const IcGroup = (props: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        {...props}
    >
        <Mask
            id="ic_group"
            width={24}
            height={24}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
        >
            <Path fill="#D9D9D9" d="M0 0h24v24H0z" />
        </Mask>

        <G mask="url(#ic_group)">
            <Path
                fill="#1C1B1F"
                d="M1.798 19.308v-2.223c0-.516.133-.976.399-1.38a2.72 2.72 0 0 1 1.066-.944c.95-.465 1.906-.823 2.867-1.075.96-.252 2.017-.378 3.168-.378 1.151 0 2.207.126 3.168.378.961.252 1.917.61 2.867 1.075.444.225.8.54 1.066.944.266.404.399.864.399 1.38v2.223h-15Zm17 0v-2.346c0-.657-.16-1.282-.482-1.877a4.544 4.544 0 0 0-1.368-1.531c.67.1 1.307.255 1.91.464.602.21 1.177.458 1.724.744.517.275.916.6 1.197.974.282.374.423.783.423 1.226v2.346h-3.404Zm-9.5-7.616c-.963 0-1.787-.342-2.472-1.028-.685-.685-1.028-1.51-1.028-2.472s.343-1.786 1.028-2.471c.685-.686 1.51-1.029 2.472-1.029s1.786.343 2.472 1.029c.685.685 1.028 1.509 1.028 2.471 0 .963-.343 1.787-1.028 2.472-.686.686-1.51 1.028-2.472 1.028Zm8.634-3.5c0 .963-.342 1.787-1.028 2.472-.685.686-1.51 1.028-2.472 1.028-.112 0-.256-.012-.43-.038a3.796 3.796 0 0 1-.431-.085c.394-.474.697-1 .91-1.578a5.19 5.19 0 0 0-.007-3.593 5.783 5.783 0 0 0-.903-1.583 1.87 1.87 0 0 1 .43-.1c.144-.015.288-.023.431-.023.963 0 1.787.343 2.472 1.029.686.685 1.028 1.509 1.028 2.471ZM3.298 17.808h12v-.723a1.01 1.01 0 0 0-.157-.558c-.104-.163-.27-.305-.497-.427a11.797 11.797 0 0 0-2.542-.964 11.5 11.5 0 0 0-2.804-.328 11.5 11.5 0 0 0-2.804.328c-.872.218-1.72.54-2.542.964-.227.122-.393.264-.497.427a1.01 1.01 0 0 0-.157.558v.723Zm6-7.616c.55 0 1.02-.195 1.412-.587.392-.392.588-.863.588-1.413s-.196-1.02-.588-1.412a1.926 1.926 0 0 0-1.412-.588c-.55 0-1.021.196-1.413.588a1.926 1.926 0 0 0-.587 1.412c0 .55.196 1.021.587 1.413.392.392.863.587 1.413.587Z"
            />
        </G>
    </Svg>
);
export default IcGroup;
