import {Box, Row, ScrollView} from 'native-base';
import {ChallengeFeaturedItem} from '@ui/features/challenge/ChallengeFeaturedItem';
import {CompetitionUiDataWithLabel} from '@shared/models/configs';
import {Platform} from 'react-native';
import React from 'react';

interface Props {
    items: CompetitionUiDataWithLabel[];
}
export const FeaturedChallenges = (props: Props) => {
    const {items} = props;
    return (
        <Box w='full'>
            <ScrollView horizontal showsHorizontalScrollIndicator={Platform.select({web: true, default: false})} >
                <Row space='2' w='full'>
                    {items.map(challenge => {
                        return (
                            <ChallengeFeaturedItem
                                item={challenge}
                                key={challenge.competition.id}
                                width={180}
                            />
                        );
                    })}
                </Row>
            </ScrollView>
        </Box>
    );
};
