import {Box, IBoxProps, Text, useBreakpointValue} from 'native-base';
import {Dimensions} from 'react-native';
import {ImageUris} from '@shared/models/image';
import NavigationLink from '@ui/components/buttons/NavigationLink';
import {PolygonBackground} from '@ui/components/PolygonBackground';
import React from 'react';
import {UrlObject} from 'url';
import WAMPImage from '@ui/components/image/WAMPImage';

const SIDE_PADDING = 32;
const INTERNAL_PADDING = 32;
const SPACE_BETWEEN = 8;

const calculatedWidth = (Dimensions.get('window').width - SIDE_PADDING - INTERNAL_PADDING - SPACE_BETWEEN) / 2;

interface FeaturedItemProps extends IBoxProps {
    path: string | UrlObject;
    image?: ImageUris;
    alt?: string;
    label?: string;
}

export const FeaturedItem = (props: FeaturedItemProps) => {
    const {path, image, label, alt, width = calculatedWidth, ...rest} = props;

    const showLabel = label && label.length > 0;

    const elementWidth = useBreakpointValue({base: (width as number)});
    const elementHeight = elementWidth * 1.25;

    return (
        <NavigationLink path={path} isCard>
            <Box
                w={elementWidth}
                h={elementHeight}
                overflow='hidden'
                rounded='xl'
                {...rest}
            >
                {image ?
                    <WAMPImage
                        contentFit="cover"
                        src={image.xl}
                        fill
                        alt={alt ?? 'image'}
                        image={image}
                    /> :
                    <PolygonBackground />}

                <Box position="absolute" right={1} top={2} bg={showLabel ? 'lime.default' : 'transparent'} rounded='md' overflow="hidden" px='2' py={1}>
                    {showLabel && <Text variant='smMedium' fontSize={11} color='darkGreen'>{label}</Text>}
                </Box>
            </Box>
        </NavigationLink>
    );
};
