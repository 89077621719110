import {Box, Button, Center} from 'native-base';
import IcChevronRight from '@ui/svg/IcChevronRight';
import {Platform} from 'react-native';
import {PresenceLoadingScreen} from '@ui/components/LoadingScreen';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface LoadMoreButtonProps {
    loadMore: () => void;
    loadingMore: boolean;
    hasMore: boolean;
}

const LoadMoreButton = (props: LoadMoreButtonProps) => {
    const {loadMore, loadingMore, hasMore} = props;
    const {t} = useTranslation();

    const showLoadMoreButton = Platform.OS === 'web' && hasMore;

    return (
        <PresenceLoadingScreen isLoading={loadingMore} h='100'>
            {showLoadMoreButton ? (
                <Center h='full'>
                    <Button variant='ghostPrimary' leftIcon={<IcChevronRight style={{transform: [{rotate: '90deg'}]}} />} onPress={loadMore}>{t('actions.more')}</Button>
                </Center>
            )
                : <Box />
            }
        </PresenceLoadingScreen>
    );
};

export {LoadMoreButton};
